import React from 'react';
import { Field, Label, MediaInput, Message } from "@zendeskgarden/react-forms";
import { AuthenticationService } from '../../../services/auth.service';
import Loading from '../../../components/loading';
import { ReactComponent as LockIcon } from "@zendeskgarden/svg-icons/src/16/lock-locked-fill.svg";
import { ReactComponent as UnlockIcon } from "@zendeskgarden/svg-icons/src/16/lock-unlocked-fill.svg";
import { AlertService } from '../../../services/alert.service';
import { t } from '../../../translations/translation.service';
import "./index.css"

class ChangePassword extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    newPassword: "",
    confirmPassword: "",
    loading: false,
    message: "",
    showNewPassword: false,
    showConfirmPassword: false,
    verify: false,
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const checked = await AuthenticationService.checkToken(this.props.match.params.id);
    if (checked.status === 200) {
      this.setState({ loading: false, message: checked.data.message});
    }
  }

  handleChange(event: any) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  verifyFields(): boolean {
    if (
      this.state.newPassword.length >= 6 &&
      this.state.newPassword === this.state.confirmPassword
    ) {
      return true;
    }
    return false;
  }

  async handleSubmit() {
    this.setState({ verify: true });
    const checkedFields = this.verifyFields();
    if (!checkedFields) {
      return AlertService.error(t("erro verifique os campos"));
    }
    this.setState({ loading: true });
    const { newPassword, confirmPassword } = this.state;
    const response = await AuthenticationService.resetPassword(
      this.props.match.params.id, newPassword, confirmPassword
      );
    if (response.status !== 200) {
      this.setState({ loading: false });
      return AlertService.error(t("Erro! Tente novamente mais tarde."))
    }
    this.setState({ loading: false });
    AlertService.success(t("Senha alterada com sucesso!"));
    setTimeout(() => {
      this.props.history.push("/login");
    }, 500);
  }

  render() {
    if (this.state.message === "TOKEN_INVALID_OR_EXPIRED") {
      return (
        <div className='container-invalid-token'>
          <span className="invalid-token">
            {t("Token expirado ou Inválido ")}
          </span>
          <span className="invalid-token">
            {t("Clique ")}<a href="/forgot-password">{t("aqui")}</a>{t(" e tente novamente.")}
          </span>
        </div>
      );
    }
    return (
        <div className="d-flex-column">
          <Loading show={this.state.loading}/>
          <h1 className="reset-password-title">
            {t("Redefinição de Senha")}
          </h1>
          <div className="container-field-reset-password">
            <Field>
              <Label className="label-field-reset-password">
                <span className="text-label">{t("Senha")}</span>
                <MediaInput
                  value={this.state.newPassword}
                  name="newPassword"
                  className="mt-2"
                  type={this.state.showNewPassword ? "text" : "password"}
                  onChange={this.handleChange}
                  end={
                    this.state.showNewPassword ? (
                      <UnlockIcon
                        cursor={"pointer"}
                        onClick={() =>
                          this.setState({ showNewPassword: false })
                        }
                      />
                    ) : (
                      <LockIcon
                        cursor={"pointer"}
                        onClick={() =>
                          this.setState({ showNewPassword: true })
                        }
                      />
                    )
                  }
                  validation={
                    this.state.verify ?
                      this.state.newPassword.length >= 6
                      ? "success"
                      : "error"
                    : undefined
                  }
                  
                />
                <Message
                  validation={
                    this.state.verify ?
                      this.state.newPassword.length >= 6
                        ? "success"
                        : "error"
                    : undefined
                  }
                >
                  {
                    this.state.verify ?
                      this.state.newPassword.length >= 6
                        ? "OK"
                        : "A senha deve ter no mínimo 6 caracteres"
                    : undefined
                  }
                </Message>
              </Label>
            </Field>
            <Field>
              <Label className="label-field-reset-password">
                <span className="text-label">{t("Confirmar Senha")}</span>
                <MediaInput
                  value={this.state.confirmPassword}
                  name="confirmPassword"
                  className="mt-2"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  onChange={this.handleChange}
                  end={
                    this.state.showConfirmPassword ? (
                      <UnlockIcon
                        cursor={"pointer"}
                        onClick={() =>
                          this.setState({ showConfirmPassword: false })
                        }
                      />
                    ) : (
                      <LockIcon
                        cursor={"pointer"}
                        onClick={() =>
                          this.setState({ showConfirmPassword: true })
                        }
                      />
                    )
                  }
                  validation={
                    this.state.verify &&
                    this.state.confirmPassword.length >= 6
                      ? this.state.newPassword ===
                        this.state.confirmPassword
                        ? "success"
                        : "error"
                      : undefined
                  }
                />
                <Message
                  validation={
                    this.state.verify &&
                    this.state.confirmPassword.length >= 6
                      ? this.state.newPassword ===
                        this.state.confirmPassword
                        ? "success"
                        : "error"
                      : undefined
                  }
                >
                  {
                    this.state.verify &&
                    this.state.confirmPassword.length >= 6
                    ? this.state.newPassword ===
                      this.state.confirmPassword
                      ? "OK"
                      : "Senhas não conferem"
                    : undefined
                  }
                </Message>
              </Label>
            </Field>
          </div>
          <button
            className="button-reset-password"
            onClick={this.handleSubmit}
          >
            {t("Salvar")}
          </button>
        </div>
    );
  }

}

export default ChangePassword;
