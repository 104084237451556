import {
  Dropdown,
  Field as Field2,
  Item,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import {
  Field,
  Field as ToggleField,
  Input,
  Label,
  MediaInput,
  Message,
  Toggle
} from "@zendeskgarden/react-forms";
import { Dots } from "@zendeskgarden/react-loaders";
import { ReactComponent as LockIcon } from "@zendeskgarden/svg-icons/src/16/lock-locked-fill.svg";
import { ReactComponent as UnlockIcon } from "@zendeskgarden/svg-icons/src/16/lock-unlocked-fill.svg";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../actions";
import HeaderMobile from "../../components/header";
// import SEO from "../../components/seo";
import { UserData } from "../../model/user.model";
import { LOGIN_ROUTE, SIGNUP_LOCATION_ROUTE } from "../../providers/routes";
import TranslationService, { t } from "../../translations/translation.service";
import "./index.css";
import UserService from "../../services/user.service";

const { zdColorSecondaryOrange400 } = require("@zendeskgarden/css-variables");
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

class Signup extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
    user: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      pcd: false,
    },
    width: window.innerWidth,
    lock: true,
    firstNameValid: true,
    lastNameValid: true,
    emailValid: true,
    checkEmailExists: false,
    passwordValid: true,
    inputPassword: "password",
    formValid: false,
    loadingSubmit: false,
    europeanUnionCitizenship: false,
    emailTouched: false, // Adicione esta linha
  };

  constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lockPassword = this.lockPassword.bind(this);
  }

  
  componenDidUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  redirectToHome() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
  }

  componentDidUpdate(prevProps: Readonly<any>, _prevState: Readonly<{}>): void {
    if (
        this.state.width <= 680 &&
        prevProps.currentLanguage !== this.props.currentLanguage
        && typeof(this.props.currentLanguage) !== "function"
      ) {
      this.setState({ currentLang: this.props.currentLanguage});
    }
  }

  handleSubmit(event: any) {
    this.setState({ loadingSubmit: true, emailTouched: true }, () => this.lockPassword(true));
  
    event.preventDefault();
    
    const userData: Partial<UserData> = {
      email: this.state.user.email,
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      password: this.state.user.password,
      dadosPessoais: {
        pcdUser: {
          isUserPCD: this.state.user.pcd,
          defs: [],
        },
        localidade: undefined,
        geolocation: undefined,
        indicadoPor: undefined,
      },
      europeanUnionCitizenship: this.state.europeanUnionCitizenship,
      vagaId: this.props.location?.vagaId
    };
  
    this.props.history.push({
      pathname: SIGNUP_LOCATION_ROUTE,
      data: userData,
    });
  }

  handleEmailBlur(event: any) {
    const target = event.target;
    const value = target.value;
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ) {
      return;
    }
    UserService.checkEmail(value).then((response:any) => {
      if (response.data.exists) {
        this.setState({ checkEmailExists: true });
        return;
      }
      this.setState({ checkEmailExists: false });

    });
  }


  lockPassword(value: boolean) {
    this.setState({ lock: value, inputPassword: value ? "password" : "text" });
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        user: {
          ...this.state.user,
          [name]: value,
        },
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  validateField(fieldName: string, value: any) {
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        break;
      case "password":
        passwordValid = value.length >= 8;
        break;
      case "firstName":
        firstNameValid = value.length >= 3;
        break;
      case "lastName":
        lastNameValid = value.length >= 3;
        break;
      default:
        break;
    }
    this.setState(
      {
        firstNameValid,
        lastNameValid,
        emailValid,
        passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: !!(
        this.state.emailValid &&
        this.state.user.email &&
        this.state.passwordValid &&
        this.state.user.password &&
        this.state.firstNameValid &&
        this.state.user.firstName &&
        this.state.lastNameValid &&
        this.state.user.lastName
      ),
    });
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 680;
    const { currentLanguageChanged } = this.props;
    const langs = TranslationService.getLanguages();

    if (!isMobile) {
      return (
        <>
          {/* <SEO title={`Enter - ${t("Cadastre-se")}`} url='https://elumini.enter.jobs/signup'/> */}
          <div className="bg-enter">
            <div className="d-flex align-items-center justify-items-center h-100">
              <div className="bg-container">
                <div className="content-signup d-flex justify-content-between justify-items-center flex-column">
                  <div>
                    <div className="row">
                      <div className="col">
                        <p
                          className="enter-logo"
                          style={{ cursor: "pointer" }}
                          onClick={this.redirectToHome.bind(this)}
                        />
                      </div>
                      <div className="d-flex align-items-center mr-1">
                        <Dropdown
                          selectedItem={TranslationService.getCurrentLanguage()}
                          onSelect={(selectedLang) => {
                            TranslationService.changeLanguage(selectedLang);
                            this.setState({ currentLang: selectedLang });
                            currentLanguageChanged(selectedLang);
                          }}
                          downshiftProps={{
                            itemToString: (item: any) => item,
                          }}
                        >
                          <Field2>
                            <Select className="u-semibold u-fs-sm border-0">
                              {t(TranslationService.getCurrentLanguage())}
                            </Select>
                          </Field2>
                          <Menu className="u-fs-sm">
                            {langs.map((option: any) => (
                              <Item key={option} value={option}>
                                {t(option)}
                              </Item>
                            ))}
                          </Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <form className="login-body" onSubmit={this.handleSubmit}>
                      <h1 className="signup-title mb-4">{t("Cadastre-se")}</h1>
                      <Field className="mt-2">
                        <Input
                          disabled={this.state.loadingSubmit}
                          validation={
                            this.state.firstNameValid ? undefined : "error"
                          }
                          onChange={this.handleInputChange}
                          name="firstName"
                          placeholder={t("Nome")}
                        ></Input>
                        {!this.state.firstNameValid && (
                          <Message validation="error">
                            {t("Nome inválido.")}
                          </Message>
                        )}
                      </Field>

                      <Field className="mt-2">
                        <Input
                          disabled={this.state.loadingSubmit}
                          validation={
                            this.state.lastNameValid ? undefined : "error"
                          }
                          onChange={this.handleInputChange}
                          name="lastName"
                          placeholder={t("Sobrenome")}
                        ></Input>
                        {!this.state.lastNameValid && (
                          <Message validation="error">
                            {t("Sobrenome inválido.")}
                          </Message>
                        )}    
                      </Field> 
                      <Field className="mt-2">
                        <Input
                          disabled={this.state.loadingSubmit}
                          validation={
                            this.state.emailTouched && !this.state.user.email
                              ? "error"
                              : this.state.emailValid
                              ? this.state.checkEmailExists
                                ? "error"
                                : undefined
                              : "error"
                          }
                          onChange={this.handleInputChange}
                          onBlur={this.handleEmailBlur.bind(this)}
                          name="email"
                          placeholder={t("E-mail")}
                        />
                        <div className="validation-messages">
                          {this.state.emailTouched && !this.state.user.email ? (
                            <Message validation="error">{t("Email obrigatório!")}</Message>
                          ) : !this.state.emailValid ? (
                            <Message validation="error">
                              {t("Email inválido.")}
                              </Message>
                          ) : this.state.checkEmailExists ? (
                            <Message validation="error">
                              {t("Email já cadastrado.")}
                              </Message>
                          ) : null}
                        </div>
                      </Field>

                      <Field className="mt-2">
                        <MediaInput
                          name="password"
                          disabled={this.state.loadingSubmit}
                          validation={
                            this.state.passwordValid ? undefined : "error"
                          }
                          type={this.state.inputPassword}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputChange}
                          placeholder={t("Senha")}
                          end={
                            <React.Fragment>
                              {!this.state.lock && (
                                <UnlockIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.lockPassword(true)}
                                ></UnlockIcon>
                              )}
                              {this.state.lock && (
                                <LockIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.lockPassword(false)}
                                ></LockIcon>
                              )}
                            </React.Fragment>
                          }
                        />
                        {!this.state.passwordValid && (
                          <Message validation="error">
                            {t("Senha inválida.")}
                          </Message>
                        )}
                      </Field>
                      <ToggleField className="text-left mt-3 align-items-center">
                        <Toggle
                          name="pcd"
                          onChange={(e) =>
                            this.setState({
                              user: { ...this.state.user, pcd: e.target.checked },
                            })
                          }
                        >
                          <Label className="pcd-toggle">{`${t(
                            "Pessoa com deficiência"
                          )}`}</Label>
                        </Toggle>
                      </ToggleField>
                      <ToggleField className="text-left mt-3 align-items-center">
                        <Toggle
                          name="europeanUnionCitizenship"
                          onChange={(e) =>
                            this.setState({
                              europeanUnionCitizenship: e.target.checked,
                            })
                          }
                        >
                          <Label className="pcd-toggle">{`${t(
                            "Cidadania Europeia"
                          )}`}</Label>
                        </Toggle>
                      </ToggleField>

                      <button
                        type="submit"
                        disabled={
                          !this.state.formValid || this.state.loadingSubmit
                        }
                        className="btn-enviar w-100 mt-3 button-entrar"
                      >
                        {!this.state.loadingSubmit && t("Continuar")}
                        {this.state.loadingSubmit && (
                          <Dots
                            key="dots-loading"
                            color={zdColorSecondaryOrange400}
                            size="32px"
                          />
                        )}
                      </button>
                    </form>
                  </div>
                  <div>
                    <p
                      style={{ marginTop: "4rem" }}
                      className="text-center u-fg-grey-500"
                    >
                      {t("Já é cadastrado?")}{" "}
                      <a href={LOGIN_ROUTE} className="u-semibold u-fg-grey-600">
                        {t("Clique aqui para entrar")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <HeaderMobile />
          <div className="login-body bg-enter p-4 d-flex justify-content-between flex-column">
            <div className="container">
              <h1 className="login-title mt-0 text-light">
                {t("Cadastre-se")}
              </h1>
              <form className="login-body" onSubmit={this.handleSubmit}>
                <Field className="mt-3">
                  <Input
                    disabled={this.state.loadingSubmit}
                    validation={this.state.firstNameValid ? undefined : "error"}
                    onChange={this.handleInputChange}
                    name="firstName"
                    placeholder={t("Nome")}
                  ></Input>
                  {!this.state.firstNameValid && (
                    <Message validation="error">{t("Nome inválido.")}</Message>
                  )}
                </Field>
                <Field className="mt-3">
                  <Input
                    disabled={this.state.loadingSubmit}
                    validation={this.state.lastNameValid ? undefined : "error"}
                    onChange={this.handleInputChange}
                    name="lastName"
                    placeholder={t("Sobrenome")}
                  ></Input>
                  {!this.state.lastNameValid && (
                    <Message validation="error">
                      {t("Sobrenome inválido.")}
                    </Message>
                  )}
                </Field>
                <Field className="mt-2">
                  <Input
                    disabled={this.state.loadingSubmit}
                    validation={
                      this.state.emailTouched && !this.state.user.email
                        ? "error"
                        : this.state.emailValid
                        ? this.state.checkEmailExists
                          ? "error"
                          : undefined
                        : "error"
                    }
                    onChange={this.handleInputChange}
                    onBlur={this.handleEmailBlur.bind(this)}
                    name="email"
                    placeholder={t("E-mail")}
                  />
                  <div className="validation-messages">
                    {this.state.emailTouched && !this.state.user.email ? (
                      <Message validation="error">{t("Email obrigatório!")}</Message>
                    ) : !this.state.emailValid ? (
                      <Message validation="error">{t("Email inválido.")}</Message>
                    ) : this.state.checkEmailExists ? (
                      <Message validation="error">{t("Email já cadastrado.")}</Message>
                    ) : null}
                  </div>
                </Field>    
                <Field className="mt-3">
                  <MediaInput
                    name="password"
                    disabled={this.state.loadingSubmit}
                    validation={this.state.passwordValid ? undefined : "error"}
                    type={this.state.inputPassword}
                    onChange={this.handleInputChange}
                    placeholder={t("Senha")}
                    end={
                      <React.Fragment>
                        {!this.state.lock && (
                          <UnlockIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => this.lockPassword(true)}
                          ></UnlockIcon>
                        )}
                        {this.state.lock && (
                          <LockIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => this.lockPassword(false)}
                          ></LockIcon>
                        )}
                      </React.Fragment>
                    }
                  />
                  {!this.state.passwordValid && (
                    <Message validation="error">{t("Senha inválida.")}</Message>
                  )}
                </Field>

                <ToggleField className="text-left mt-3 align-items-center">
                  <Toggle
                    name="pcd"
                    onChange={(e) =>
                      this.setState({
                        user: { ...this.state.user, pcd: e.target.checked },
                      })
                    }
                  >
                    <Label className="pcd-toggle">{`${t(
                      "Pessoa com deficiência"
                    )}`}</Label>
                  </Toggle>
                </ToggleField>
                <ToggleField className="text-left mt-3 align-items-center">
                  <Toggle
                    name="europeanUnionCitizenship"
                    onChange={(e) =>
                      this.setState({
                        europeanUnionCitizenship: e.target.checked,
                      })
                    }
                  >
                    <Label className="pcd-toggle">{`${t(
                      "Cidadania Europeia"
                    )}`}</Label>
                  </Toggle>
                </ToggleField>
                <button
                  type="submit"
                  disabled={!this.state.formValid || this.state.loadingSubmit}
                  className="btn-enviar w-100 mt-4"
                >
                  {!this.state.loadingSubmit && t("Continuar")}
                  {this.state.loadingSubmit && (
                    <Dots
                      key="dots-loading"
                      color={zdColorSecondaryOrange400}
                      size="32px"
                    />
                  )}
                </button>
              </form>
            </div>
            <p style={{ marginTop: "5rem" }} className="text-center text-light">
              {t("Já é cadastrado?")}{" "}
              <a href={LOGIN_ROUTE} className="u-semibold text-light">
                {t("Clique aqui para entrar")}
              </a>
            </p>
          </div>
          {/* <pre>{JSON.stringify(this.state.user, null, 2)}</pre> */}
        </>
      );
    }
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
