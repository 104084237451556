import React from "react";
import './index.css';
// import '@zendeskgarden/react-avatars/dist/styles.css';
import { Avatar } from '@zendeskgarden/react-avatars';
import { withRouter } from "react-router-dom";
import amountIcon from "../../../images/icons/amount-icon.png";
import xIcon from "../../../images/icons/icon_x_white.png";
import checkIcon from "../../../images/icons/icon_check_white.png";
import avatarPng from "../../../images/icons/default-user.png";
import { ReactComponent as ClockIcon } from "@zendeskgarden/svg-icons/src/16/clock-stroke.svg";
import FileStackService from "../../../services/filestack.service";
import moment from "moment";
import { dateDefeat } from "../../../model/opportunity.model";

class CardMinor extends React.Component<any> {
  id: string = this.props.id;
  item: any = this.props.item;

  state = {
    policyAndSignatureImg: '' as string,
  }

  handleClick = () => {
    const url = `/kanban/card-details/${this.item.id}`;
    window.open(url, "_blank"); // Abre a página em uma nova aba
  };
  

  async getFileStackPolicyToUserImg(): Promise<void> {
    if (this.item.responsable !== null && this.item.responsable.hasOwnProperty('userImageUrl')) {
      const validURL = this.item.responsable.userImageUrl
      try {
        if (this.getNewFileStackUrl(validURL)) {
          const policyUserImg = await FileStackService.getFileStackSecreteCode(
            validURL, false
          );
          this.setState({ policyAndSignatureImg: policyUserImg.data });
        }
      } catch(err: any) {
        console.log("Erro ao obter credenciais - ERROR: ", err);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    await this.getFileStackPolicyToUserImg();
  }

  setUrlImg(url: string): string {
    const { policyAndSignatureImg } = this.state;
    const splitedUrl = url.split('.com');
    splitedUrl.splice(1,0, '.com'+policyAndSignatureImg);
    const newUrl = splitedUrl.join('');
    return newUrl;
  }

  getNewFileStackUrl(url: string): boolean {
    const domain = url.split('.com/')[0].split('//')[1]
    if (domain !== 'cdn.filestackcontent') {
      return false;
    }
    return true;
  }

  checkDateVictory(candidateList: any) {
    if (candidateList) {
      return this.getCandidatesVictories(candidateList).length > 0;
    }
  };

  getCandidatesVictories(candidateList: any) {
    const Dates = candidateList.filter((candidate: any) => {
      return candidate.dateVictory !== null;
    });
    return Dates;
  };

  getDateVictories(Dates: any) {
    const allDates = Dates.map((date: any) => {
      return date.dateVictory
    });
    return allDates;
  };

  getFirstDateVictory (candidateList: any): any {
    let minDate:any = null;
    if (candidateList) {
      const candidates = this.getCandidatesVictories(candidateList);
      const Dates = this.getDateVictories(candidates);
      Dates.forEach((date: string) => {
        if(minDate === null){
          minDate = date;
        }else {
          if(new Date(minDate) > new Date(date)) {
            minDate = date
          };
        };
      })
      return "Data: " + moment(minDate).format("DD-MM-YY");
    }
  }

  getDateDefeated(defeats: dateDefeat[]) {
    let minDate:any = null;
    
    if(defeats && defeats.length < 1) return "";
    if (defeats) {
      defeats.forEach((date: dateDefeat) => {
        if (minDate === null) {
          minDate = date.date
        } else {
          if (new Date(minDate) > new Date(date.date)) {
            minDate = date.date;
          }
        }
      });
    }
    return "Data: " + moment(minDate).format("DD-MM-YY");
  }

  render() {
    if (this.item.responsable !== null && this.item.responsable?.userImageUrl){
      this.item.avatar = this.item.responsable.userImageUrl;
    }else{
      this.item.avatar = avatarPng;
    }

    this.handleClick = this.handleClick.bind(this);
    const squadColor = this.item.label === '2'? ' inactive': this.item.squad && this.item.squad._id;
    return (
      <summary id={this.item.id} className={`card border-left-${squadColor} shadow h-100 py-2 mb-3`} onClick={this.handleClick}>
        <div className={`squad-color-${squadColor} u-fs-sm mb-1 ml-2`}>
          <div className="header-victory-defeat-container">
            <p>
              {this.item.client}
            </p>
            <div className="victory-defeat-container">
              {this.checkDateVictory(this.item.candidatos)
                ? <span
                  className="victory-date"
                >
                    {this.getFirstDateVictory(this.item.candidatos)}
                  </span>
                : ""}
                {
                  this.item.datesDefeats &&
                  this.item.datesDefeats.length > 0 &&
                  <span
                    className="defeat-date"
                  >
                    {this.getDateDefeated(this.item.datesDefeats)}
                  </span>
                }
            </div>
          </div>
        </div>
        <div className=" u-fs-md mb-1 ml-2">{this.item.title}</div>
        <div className="u-position-absolute mb-2 d-flex" style={{'width':'100%', 'bottom': 0}}>
          <div className="u-fs-small">
            <Avatar className="ml-2" size="small" title={this.item.responsable? this.item.responsable.displayName : "User Not Found"} style={{'border': '1px solid #4F6061'}}>
              <img
                src={
                  this.getNewFileStackUrl(this.item.avatar)
                    ? this.setUrlImg(this.item.avatar)
                    : this.item.avatar
                }
                alt=""
              />
            </Avatar>
            {this.props.id !== "closed" && 
            <>
              <ClockIcon className="ml-2 clock-card-kanbon" title={this.item.periodInCurrentStep + " dias nesta etapa"}/>
              <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">{this.item.periodInCurrentStep}</span>
            </>}
            <img className="ml-2" src={amountIcon} alt="Quantidade de Vagas" title={"Quantidade de Vagas"} />
            <span className="ml-1 u-fs-sm u-semibold u-fg-grey-600">{this.item.amount}</span>
          </div>
          {this.item.label === '2' && this.props.id !== "closed" ? 
            <div  className="ml-auto">
              <span className="background-label-default label-fs text-uppercase">INATIVA</span>
            </div> :''
          }

          {(this.item.victories > 0 || this.item.defeats > 0) && this.props.id === "closed"?
            <div  className="ml-auto">
              <div className="d-flex flex-row u-fs-sm ml-1 u-fg-white">
                <div className="background-label-victories">
                  <img src={checkIcon} alt="" className="mr-1 mt-1" />
                  {this.item.victories}
                </div>
                <div className="ml-1 mr-2 background-label-defeats">
                  <img src={xIcon} alt=""  className="mr-1 mb-1"/>
                  {this.item.defeats}
                </div>
              </div>
            </div> : ''
          }
        </div>
      </summary>
    );
  }
}

export default withRouter (CardMinor);
