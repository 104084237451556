import { Dropdown, Field as DropField, Select, Menu, Item} from "@zendeskgarden/react-dropdowns";
import { Field, Input, Label, Checkbox } from "@zendeskgarden/react-forms";
import { Col, Row } from "@zendeskgarden/react-grid";
import { FooterItem } from "@zendeskgarden/react-modals";
import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import Loading from "../../../components/loading";
import MosaicPreview from "../../../components/mosaic/preview";
import { Opportunity } from "../../../model/opportunity.model";
import {
  CANDIDATURE_DETAILS_ROUTE,
  COMPATIBLE_PROFESSIONALS_ROUTE, LOGIN_ROUTE, OPPORTUNITIES_ROUTE,
  OPPORTUNITY_COPY_ROUTE,
  OPPORTUNITY_DETAIL_ROUTE,
  OPPORTUNITY_EDIT_MOSIAIC_ROUTE,
  OPPORTUNITY_EDIT_ROUTE, PRE_REGISTER_ROUTE, PROFESSIONAL_ROUTE
} from "../../../providers/routes";
import { AlertService } from "../../../services/alert.service";
import { AuthenticationService } from "../../../services/auth.service";
import OpportunityService from "../../../services/opportunity.service";
import Translate, { t } from "../../../translations/translation.service";
import {
  showTitleLinkedinFn,
  whatsAppOportunity
} from "../../../util/opportunity";
import ReactGA from 'react-ga4';
import style from "./index.module.scss";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { theme } from "../../../util/colorButton";
// import SEO from "../../../components/seo";

const reasonsList = [
  { label: 'Fechado com candidato Elumini'},
  { label: 'Fechado com outra consultoria'},
  { label: 'Cancelamento'},
  { label: 'Cancelamento sem retorno do cliente' },
  { label: 'Outros'}
]

class OpportunityDetail extends React.Component<any> {
  state = {
    message: undefined as unknown,
    loading: true,
    opportunity: {} as Opportunity,
    width: 0,
    height: 0,
    endOppModal: false,
    deleteOppModal: false,
    linkedinTitle: false,
    changeText: false,
    linkedinKey: undefined,
    candidatureModal: false,
    indicadoPor: "",
    sendMailToProfessionals: true,
    selectedReason: reasonsList[0],
    description: "",
    reasons: reasonsList,
  }; 

  footerRef!: any;

  constructor(props: any) {
    super(props);
    this.footerRef = React.createRef();
    this.updateWidth = this.updateWidth.bind(this);
    this.handleCandidatura = this.handleCandidatura.bind(this);
  }

  updateWidth() {
    const width = this.footerRef.current.parentNode.clientWidth;
    const height = this.footerRef.current.parentNode.clientHeight;
    this.setState({ width, height });
  }

  getOpportunityStep(step: number) {
    if (this.state.opportunity.isPublic || this.state.opportunity.label === '1' || this.state.opportunity?.lifeCycle.length >= 1) {
      if (this.state.opportunity.quantidadeVagas === 0) return t("Encerrada");
      switch (step) {
        case 0:
          return t("Recrutamento");
        case 1:
          return t("Recrutamento");
        case 2:
          return t("Avaliação");
        case 3:
          return t("Seleção");
        case 4:
          return t("Apresentação");
      }
    } else if (this.state.opportunity?.lifeCycle.length >= 1) {
      return t("Recrutamento");
    } else {
      return t("Revisão");
    }
  }

  async getLinkedinKeyWords() {
    this.setState({ linkedinTitle: false, changeText: false });
    await OpportunityService.linkedinKeyWords(this.props.match.params.id)
      .then((res) => this.setState({ linkedinKey: res.data.dataKey }))
      .catch((err) => err);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({ message: undefined });
      OpportunityService.getOpportunityLiteKanban(id)
        .then((resp) => {
          if (
            (resp.data.isFinished ||
              !resp.data.isPublic ||
              resp.data.quantidadeVagas < 1) &&
            !AuthenticationService.isRh()
          ) {
            this.setState({
              message: "A oportunidade não está mais disponível.",
            });
            this.setState({ loading: false });
            return;
          }

          this.setState({ opportunity: resp.data, loading: false }, () => {
            this.updateWidth();
            window.addEventListener("resize", this.updateWidth);
            this.opportunityViewEvent(this.state.opportunity.titulo);
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ message: "Vaga não encontrada" });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false, message: "Vaga não encontrada" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  endOpportunityEvent() {
    ReactGA.event({
      category: "Recruitment-actions",
      action: "Finalizar Oportunidade",
      label: "rh",
    });
  }

  userCandidatureEvent(oppName: string) {
    ReactGA.event({
      category: "professional-actions",
      action: `Candidatura à vaga - ${oppName}`,
      label: "professional",
    });
  }

  opportunityViewEvent(oppName: string) {
    if (AuthenticationService.isRh()) {
      ReactGA.event({
        category: "Recruitment-actions",
        action: `(recrutamento) oportunidade - (${oppName})`,
        label: "rh"
      })
    } else {
      ReactGA.event({
        category: "professional-actions",
        action: `(profissional) oportunidade - (${oppName})`,
        label: "professional"
      })
    }
  }

  endOpportunity() {
    this.setState({ loading: true });
    try {

      if(this.state.description === null || 
        this.state.description === undefined ||
        this.state.description === '')
      {
        this.setState({ loading: false, endOppModal: false });
        AlertService.error(t("Campo Descrição é de preenchimento obrigatório."));
        return;
      }

      const payload = {
        activeLanguage: Translate.getCurrentLanguage(),
        sendMailToProfessionals: this.state.sendMailToProfessionals,
        selectedReason: this.state.selectedReason.label,
        description: this.state.description,
      };
      const { id } = this.props.match.params;
      OpportunityService.finisheOpportunity(id, payload);
      this.setState({ loading: false, endOppModal: false ,      finalizedOpportunity: {
        reason: this.state.selectedReason.label,
        description: this.state.description, // Armazena a descrição finalizada
      },
      });
      AlertService.success(t("Oportunidade finalizada com sucesso"));
      this.endOpportunityEvent();
    } catch (err) {
      this.setState({ loading: false, endOppModal: false });
      AlertService.error(t("Erro ao finalizar Oportunidade"));
    }
  }

  async deleteOpportunity() {
    this.setState({ loading: true });
    try {
      const { id } = this.props.match.params;
      await OpportunityService.deleteOpportunity(id);
      this.setState({ loading: false, deleteOppModal: false });
      this.nextPath(OPPORTUNITIES_ROUTE);
      AlertService.success(t("Oportunidade deletada com sucesso"));
    } catch (err) {
      console.error(err);
      this.setState({ loading: false, deleteOppModal: false });
      AlertService.error(t("Erro ao deletar Oportunidade"));
    }
  }

  nextPath(path: string): void {
    this.props.history.push(path);
  }

  copyText(e: any): void {
    navigator.clipboard.writeText(e.currentTarget.innerText);
    this.setState({ changeText: true });
    setTimeout(() => {
      this.setState({
        changeText: false,
        linkedinTitle: false,
        linkedinKey: undefined,
      });
    }, 1500);
  }

  redirectToHome() {
    this.props.history.push("/");
  }

  redirectToSignUpFlux() {
    this.props.history.push({
      pathname: LOGIN_ROUTE,
      vagaId: this.state.opportunity._id,
    });
  }

  async handleCandidatura() {
    this.setState({ loading: true });
    try {
      const activeLanguage = Translate.getCurrentLanguage();
      const objData = {
        activeLanguage: activeLanguage,
        attachmentUrl: null,
        indicadoPor: this.state.indicadoPor,
        _usuario: {
          _id: AuthenticationService.getUser()._id,
        },
      };
      await OpportunityService.professionalCandidature(
        this.state.opportunity._id,
        objData
      );
      this.setState({ loading: false, candidatureModal: false });
      AlertService.success(t("Candidatura confirmada com sucesso!"));
      this.userCandidatureEvent(this.state.opportunity.titulo);
      setTimeout(() => {
        this.props.history.push(`${CANDIDATURE_DETAILS_ROUTE.replace(":opportunityId", this.state.opportunity._id)}`);
      }, 1500);
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
      AlertService.error(t("Erro na candidatura. Tente novamente!"));
    } finally {
      this.setState({ candidatureModal: false });
    }
  }

  handleDescriptionInput(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
       [name]: value,
    });
    console.log(this.state.description);
  }

  render() {
    const date = moment
      .parseZone(this.state.opportunity.created)
      .format("DD/MM/YYYY");

    // let titleSEO = this.state.opportunity.titulo 
    //   ? `Enter - ${this.state.opportunity.titulo}` : '';
    // let descriptionSEO = (Translate.getCurrentLanguage() === 'en-US' && this.state.opportunity.detalhesEn) 
    //   ? this.state.opportunity.detalhesEn
    //   : this.state.opportunity.detalhes;
    
    return (
      <div className="container">
        {/* <SEO title={titleSEO}
          description={descriptionSEO} /> */}
        <Loading show={this.state.loading} />
        {this.state.message && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h5 className="mt-3 mb-5">{t(this.state.message as string)}</h5>
            <Button
              variant="secondary"
              onClick={this.redirectToHome.bind(this)}
            >
              {t("Voltar para o mural de oportunidades")}
            </Button>
          </div>
        )}
        {this.state.opportunity.titulo && (
          <>
            <div className="row m-0">
              <div
                className={`${style["border-bottom-details"]} col-12 col-md-9 d-flex align-items-center p-0`}
              >
                <span className={style["opp-title"] + " col m-0 p-0"}>
                  {this.state.opportunity.titulo}
                </span>
                {AuthenticationService.isRh() && (
                  <span>ID: {this.state.opportunity?.idOpportunity}</span>
                )}
              </div>
            </div>
            {AuthenticationService.isRh() && (
              <div className="row m-0 mt-2">
                <div className="col-12 col-md-9 d-flex flex-column align-items-start justify-content-between p-0">
                  <div>
                    <span className="col m-0 p-0 u-semibold">
                      Status:{" "}
                      {this.state.opportunity.isFinished
                        ? t("Finalizada")
                        : this.state.opportunity.isPublic
                        ? t("Publicada")
                        : t("Não publicada")}
                      &nbsp; |&nbsp; {t("Etapa")}:{" "}
                      {this.getOpportunityStep(this.state.opportunity.fase)}
                    </span>
                    {
                      (this.state.opportunity.isFinished) &&
                      <div className="col m-0 p-0 u-semibold">
                        {t("Motivo do encerramento")}: {this?.state?.opportunity?.selectedReason?.label}
                        <div className="col m-0 p-0 u-semibold">
                          {t("Descrição do motivo")}: {this?.state?.opportunity?.description}
                        </div>
                      </div>
                    }
                  </div>
                  {/* A data de criação fica abaixo do status, motivo e descrição */}
                  <span>
                    {t("Criada em")}: {date}{" "}
                  </span>
                </div>
              </div>
            )}


            <div className="row m-0">
              <div className="col-12 col-md-9">
                <div className={`row m-0 pt-3 pb-3`}>
                  <div className="col-12 col-md-4 pl-0">
                    <div ref={this.footerRef}>
                      <MosaicPreview
                        scale={this.state.width / 550}
                        mosaic={this.state.opportunity.mosaico.escolhidas}
                      ></MosaicPreview>
                    </div>
                  </div>
                  <div className="col-8 mt-2" style={{
                    paddingLeft: 0,
                  }}>
                    {(Translate.getCurrentLanguage() === 'pt-BR' || Translate.getCurrentLanguage() === 'pt-PT' ||
                    !this.state.opportunity.detalhesEn || this.state.opportunity.detalhesEn === '' ) &&
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.opportunity.detalhes,
                        }}
                      ></div>
                    }
                    {Translate.getCurrentLanguage() === 'en-US' && 
                    this.state.opportunity.detalhesEn && this.state.opportunity.detalhesEn !== '' &&
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.opportunity.detalhesEn,
                        }}
                      ></div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 pb-3">
                {AuthenticationService.isRh() ? (
                  <>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() =>
                        this.nextPath(
                          `${OPPORTUNITY_DETAIL_ROUTE}/${this.state.opportunity._id}${COMPATIBLE_PROFESSIONALS_ROUTE}`
                        )
                      }
                    >
                      {t("Ver profissionais")}
                    </Button>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() =>
                        this.nextPath(
                          `${PROFESSIONAL_ROUTE}${PRE_REGISTER_ROUTE}/${this.state.opportunity._id}`
                        )
                      }
                    >
                      {t("Incluir pré-cadastro")}
                    </Button>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() =>
                        this.nextPath(
                          `${OPPORTUNITY_EDIT_ROUTE.replace(
                            ":id",
                            this.state.opportunity._id
                          )}`
                        )
                      }
                    >
                      {t("Editar oportunidade")}
                    </Button>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() =>
                        this.nextPath(
                          `${OPPORTUNITY_EDIT_MOSIAIC_ROUTE.replace(
                            ":id",
                            this.state.opportunity._id
                          )}`
                        )
                      }
                    >
                      {t("Editar mosaico")}
                    </Button>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() =>
                        this.nextPath(
                          `${OPPORTUNITY_COPY_ROUTE.replace(
                            ":id",
                            this.state.opportunity._id
                          )}`
                        )
                      }
                    >
                      {t("Duplicar Oportunidade")}
                    </Button>
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() => this.setState({ endOppModal: true })}
                      disabled={this.state.opportunity.isFinished}
                    >
                      {t("Finalizar Oportunidade")}
                    </Button>
                    <Button
                      disabled={
                        this.state.opportunity.quantidadeCandidatos > 0 ||
                        this.state.opportunity.quantidadeTriados > 0
                      }
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={() => this.setState({ deleteOppModal: true })}
                    >
                      {t("Excluir Oportunidade")}
                    </Button>

                    <Button
                      className={`${style["button-container"]} ${style["gray"]} mt-2`}
                      onClick={() =>
                        this.setState({
                          linkedinTitle: true,
                          linkedinKey: undefined,
                          changeText: false,
                        })
                      }
                    >
                      {t("Gerar título anúncio Linkedin")}
                    </Button>
                    {this.state.linkedinTitle && (
                      <Button
                        title="Click para copiar"
                        className={`${style["button-container"]}
                    ${style["neutro"]} mt-1 mb-1`}
                        onClick={(e) => this.copyText(e)}
                      >
                        {this.state.changeText
                          ? t("Copiado!")
                          : showTitleLinkedinFn(
                            this.state.opportunity.titulo,
                            this.state.opportunity.idOpportunity
                          )}
                      </Button>
                    )}
                    <Button
                      className={`${style["button-container"]} ${style["gray"]}`}
                      onClick={this.getLinkedinKeyWords.bind(this)}
                    >
                      {t("Gerar palavras-chave Linkedin")}
                    </Button>
                    {this.state.linkedinKey && (
                      <Button
                        title="Click para copiar"
                        className={`${style["button-container"]} ${style["neutro"]} mt-1`}
                        onClick={(e) => this.copyText(e)}
                      >
                        {this.state.changeText
                          ? t("Copiado!")
                          : this.state.linkedinKey}
                      </Button>
                    )}
                    <Button
                      className={`${style["button-container"]} ${style["green"]} mt-2`}
                      onClick={() =>
                        window.open(
                          whatsAppOportunity()
                        )
                      }
                    >
                      {t("Compartilhar")}
                    </Button>
                  </>
                ) : (
                  this.state.opportunity?.candidatos?.length > 0 ||
                    this.state.opportunity?.candidatosTriados?.length > 0 ? (
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      disabled
                    >
                      {t("Você já está concorrendo!")}
                    </Button>
                  ) :
                    <Button
                      className={`${style["button-container"]} ${style["petro"]}`}
                      onClick={
                        AuthenticationService.getUser()._id
                          ? () => this.setState({ candidatureModal: true })
                          : this.redirectToSignUpFlux.bind(this)
                      }
                    >
                      {t("Quero me candidatar!")}
                    </Button>
                )}
              </div>
            </div>
          </>
        )}
        {/* MODAL PARA FINALIZAR OPORTUNIDADE */}
        {this.state.endOppModal && (
          <Modal
            show={this.state.endOppModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => this.setState({ endOppModal: false })}
          >
            <Modal.Header className="text-center">
              <h2>{t("Finalizar Oportunidade")}</h2>
            </Modal.Header>
            <Modal.Body>
              <ThemeProvider theme={theme as any}>
                <Field>
                  <Dropdown
                    selectedItem = {this.state.selectedReason.label}
                    onSelect = {(value) => {
                      const selectedReason = value;
                      this.setState({
                       selectedReason: selectedReason,
                      })
                    }}
                    downshiftProps={{itemToString: (item: any) => item && item.label}}
                  >
                    <DropField>
                      <Label className="mt-3">Motivo do encerramento:</Label>
                      <Select className="mb-3">{this.state.selectedReason.label}</Select>
                    </DropField>
                    <Menu>
                          {this.state.reasons.map(reason => (
                            <Item key={reason.label} value={reason}>
                              {reason.label}
                            </Item>
                          ))}
                    </Menu>
                  </Dropdown>
                </Field>                
                
                <Field>
                  <Label className="mt-3">Descrição:</Label>
                  <Input 
                    className="mb-3" 
                    maxLength={80} 
                    name="description" 
                    value={this.state.description}
                    placeholder="Até 80 caracteres" 
                    onChange={(e) => this.handleDescriptionInput(e)}/>
                </Field>
              
                <Field>
                  <Checkbox
                    checked={this.state.sendMailToProfessionals}
                    onChange={() => this.setState({ sendMailToProfessionals: !this.state.sendMailToProfessionals })}
                  >
                    <Label>
                      {
                        t(
                          "Todos os candidatos serão avisados que a oportunidade foi finalizada e que não foram selecionados. Confirma?"
                        )
                      }
                    </Label>
                  </Checkbox>
                </Field>
              </ThemeProvider>
            </Modal.Body>
            <Modal.Footer>
              <Col>
                <FooterItem>
                  <Button
                    className={style["cancel-modal-button"]}
                    onClick={() => this.setState({ endOppModal: false })}
                  >
                    {t("Cancelar")}
                  </Button>
                </FooterItem>
              </Col>
              <Col>
                <FooterItem>
                <Button
                  className={style["confirm-modal-button"]}
                  onClick={() => {
                    this.endOpportunity(); // Chama a função endOpportunity
                    window.location.reload(); // Atualiza a página
                  }}
                >
                  {t("Finalizar Oportunidade")}
                </Button>

                </FooterItem>
              </Col>
            </Modal.Footer>
          </Modal>
        )}
        {/* MODAL PARA DELETAR OPORTUNIDADE */}
        {this.state.deleteOppModal && (
          <Modal
            show={this.state.deleteOppModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => this.setState({ deleteOppModal: false })}
          >
            <Modal.Header className="text-center">
              <h2>{t("Finalizar Oportunidade")}</h2>
            </Modal.Header>
            <Modal.Body>
              <p>{t("Deseja realmente excluir esta oportunidade?")}</p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-around">
              <FooterItem>
                <Button
                  onClick={() => this.setState({ deleteOppModal: false })}
                  variant="secondary"
                  style={{ padding: "3px 30px" }}
                >
                  {t("Cancelar")}
                </Button>
              </FooterItem>
              <FooterItem>
                <Button
                  onClick={this.deleteOpportunity.bind(this)}
                  variant="danger"
                  style={{ padding: "3px 30px" }}
                >
                  {t("Excluir Oportunidade")}
                </Button>
              </FooterItem>
            </Modal.Footer>
          </Modal>
        )}
        {
          // MODAL DE INSCRIÇÃO
          this.state.candidatureModal && (
            <Modal
              show={this.state.candidatureModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => this.setState({ candidatureModal: false })}
            >
              <Modal.Header>{t("Obrigado por se candidatar!")}</Modal.Header>
              <Modal.Body>
                <Row>
                  <Col lg={6} sm={12}>
                    <Field>
                      <Label>{t("Alguém te indicou para esta vaga?")}</Label>
                      <Input
                        value={this.state.indicadoPor}
                        onChange={(e: any) =>
                          this.setState({ indicadoPor: e.target.value })
                        }
                        isCompact
                      />
                    </Field>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <FooterItem>
                  <Button
                    variant="outline-warning"
                    onClick={this.handleCandidatura}
                    style={{ borderRadius: "20px", padding: "3px 25px" }}
                  >
                    {t("Continuar")}
                  </Button>
                </FooterItem>
              </Modal.Footer>
            </Modal>
          )
        }
      </div>
    );
  }
}

export default withRouter(OpportunityDetail);
