import {
  Dropdown,
  Field,
  Item,
  Menu,
  Select
} from "@zendeskgarden/react-dropdowns";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currentLanguageChanged } from "../../actions";
import { AuthenticationService } from "../../services/auth.service";
import TranslationService, { t } from "../../translations/translation.service";
import "./index.css";

class Header extends React.Component<any> {
  state = {
    currentLang: TranslationService.getCurrentLanguage,
  };

  redirectToHome() {
    this.props.history.push("/");
  }

  async componentDidMount() {
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
  }

  render() {
    const { currentLanguageChanged } = this.props;
    currentLanguageChanged(this.state.currentLang);
    const langs = TranslationService.getLanguages();
    return (
      <div className="header">
        <div className="container container-custom">
          <div className="row">
            <div className="col">
              <p
                className={AuthenticationService.isRh() ? "enter-logo-hr" : "enter-logo"}
                style={{ cursor: 'pointer' }}
                onClick={this.redirectToHome.bind(this)}
              />
            </div>
            <div className="col d-flex justify-content-end align-items-center my-custom-class">
 
              <Dropdown
                selectedItem={TranslationService.getCurrentLanguage()}
                onSelect={(selectedLang) => {
                  TranslationService.changeLanguage(selectedLang);
                  this.setState({ currentLang: selectedLang });
                  currentLanguageChanged(selectedLang);
                }}
                downshiftProps={{
                  itemToString: (item: any) => item,
                }}
              >
                <Field>
                  <Select className="u-semibold u-fs-sm border-0">
                    {t(TranslationService.getCurrentLanguage())}
                  </Select>
                </Field>
                <Menu className="u-fs-sm">
                  {langs.map((option: any) => (
                    <Item key={option} value={option}>
                      {t(option)}
                    </Item>
                  ))}
                </Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: {
  changeLanguageReducer: { currentLanguage: string };
}) => ({
  currentLanguage: store.changeLanguageReducer.currentLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      currentLanguageChanged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
