import { Stepper } from "@zendeskgarden/react-accordions";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import {
  Checkbox, Field, Hint,
  Input, Label, Message, Radio, Range,
  Textarea
} from "@zendeskgarden/react-forms";
import { Col } from "@zendeskgarden/react-grid";
import { FooterItem } from "@zendeskgarden/react-modals";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { LG, Span } from "@zendeskgarden/react-typography";
import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { ReactComponent as InfoIcon } from "../../../../images/icons/info.svg";
import { AlertService } from "../../../../services/alert.service";
import { AuthenticationService } from "../../../../services/auth.service";
import OpportunityService from "../../../../services/opportunity.service";
import UserService from "../../../../services/user.service";
import TranslationService, {
  t
} from "../../../../translations/translation.service";
import { dateHelper } from "../../../../util/date";
import CandidateFlipCard from '../../../flip-card';
import Loading from "../../../loading";
import ProfessionalAvatar from "../avatar-info";
import OpportunityCriteria from "./components/opportunity-criteria";
import styles from "./index.module.scss";
import { AssessmentType } from "./types";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import moment from "moment";
import { getValidateValue } from "../../../../actions";
import { connect } from "react-redux";

const dateFormatter = dateHelper("pt-BR");

// const StyledContainer = styled.div`
//   margin: ${(p) => p.theme.space.md} 0 0 0;
// `;

const StyledContainer = styled.div`
`;

class ProfessionalCardCandidate extends Component<{
  user: any;
  evaluations: any;
  opportunity: { id: string; title: string };
  history: any;
  level: string;
  getValidateValue: any;
}> {
  state = {
    usuarioDispensado: false,
    dismissModal: false,
    currentStep: 0,
    avaliationModal: false,
    sendEmail: false,
    loading: false,
    avaliation: {
      avaliacao: {} as any,
      opportunity: undefined,
      resultado: 0,
      user: AuthenticationService.getUser(),
      conteudo: "",
      created: new Date(),
    },
    currentLang: TranslationService.getCurrentLanguage,
    selectedUser: false,
    inscribeModal: false,
    opportunities: [] as any[],
    selectedId: "",
    processType: "",
    flip: true,
    validation: false,
  };

  constructor(props: any) {
    super(props);
    this.allSteps = this.allSteps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitEvaluation = this.submitEvaluation.bind(this);
    this.selectProfessional = this.selectProfessional.bind(this);
    this.setFlip = this.setFlip.bind(this);
    this.setAvaliationCriteria = this.setAvaliationCriteria.bind(this);
    this.setEvaliation = this.setEvaliation.bind(this);
  }

  onNext = () => this.setState({ currentStep: this.state.currentStep + 1 });
  onBack = () => {
    const resultado = 0
    this.setState({
      currentStep: this.state.currentStep - 1,
      validation: false,
      avaliation: {...this.state.avaliation, resultado},
    }, () => this.props.getValidateValue(this.state.validation));
  }

  componentDidMount() {
    this.setState({ selectedUser: this.props.user.isSelected });
  }

  checkText(text: string): boolean {
    return text.length > 0;
  }

  async submitEvaluation() {
    const { getValidateValue } = this.props;
    if (!this.checkText(this.state.avaliation.conteudo)) {
      getValidateValue(true);
      this.setState({ validation: true })
      return
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserService.registerEvaluation(
          this.props.user._usuario._id,
          this.state.avaliation
        );
        this.setState({
          avaliation: {
            ...this.state.avaliation,
            opportunity: undefined,
            avaliacao: {},
            resultado: 0,
            created: new Date(),
            conteudo: "",
          },
          loading: false,
          avaliationModal: false,
          currentStep: 0,
        });
        AlertService.success(t("Avaliação registrada com sucesso."));
      } catch (err) {
        AlertService.error("Ocorreu algum erro!");
        this.setState({ loading: false, avaliationModal: false });
      }
    });
  }

  handleChange(event: any) {
    this.setState({
      avaliation: { ...this.state.avaliation, conteudo: event.target.value },
    });
  }

  nextStep(criterio: string) {
    return (<>
      <Row className="mb-4">
        <Col textAlign="center">
          <Field className="mb-3">
            <LG>
              <Span isBold>Avaliação escolhida:</Span>{" "}
              <Span>{this.state.avaliation.avaliacao.name}</Span>{" "}
            </LG>
          </Field>
        </Col>
      </Row>
      {
        criterio ? (<Row>
          <Col textAlign="center">
            <LG>
              <Span>{criterio}</Span>
            </LG>
          </Col>
        </Row>) : <></>
      }

      <Field className="mb-4">
        <Label>{t("Data da avaliação")}</Label>
        <Datepicker
          value={this.state.avaliation.created}
          onChange={(date) =>
            this.setState({
              avaliation: { ...this.state.avaliation, created: date },
            })
          }
          formatDate={(date) => dateFormatter.format(date)}
          isCompact
        >
          <Input isCompact />
        </Datepicker>
      </Field>
      <Field className="mb-4">
        <Label>Nota</Label>
        <Hint>
          O usuário tem nota {this.state.avaliation.resultado} nessa
          avaliação.
        </Hint>
        <Range
          step={1}
          max={10}
          value={this.state.avaliation.resultado}
          onChange={(e) =>
            this.setState({
              avaliation: {
                ...this.state.avaliation,
                resultado: e.target.value,
              },
            })
          }
        />
      </Field>
      <Field>
        <Label>Avaliação</Label>
        <Textarea
          value={this.state.avaliation.conteudo}
          onChange={this.handleChange}
          minRows={2}
          maxRows={12}
          validation={
            this.state.validation &&
            this.state.avaliation.conteudo.length < 1
              ? "error"
              : undefined
          }
        />
        {
          this.state.validation &&
          this.state.avaliation.conteudo.length < 1 && (
            <Message
              validation={this.state.validation ? "error" : undefined}
            >
              {t("Preencha o campo Avaliação")}
            </Message>
          )
        }
      </Field>
    </>)
  }

  setAvaliationCriteria(criteria: any) {
    this.setState({
      avaliation: { ...this.state.avaliation, 
        opportunity: {
          id: this.props.opportunity.id,
          avaliationCriteria: criteria
        },
        resultado: undefined,
        conteudo: undefined
      },
    });
  }

  setEvaliation(evaluation: any) {
    this.setState({
      avaliation: evaluation
    });
  }

  technicalAdvice() {
    return(
      <div className="container-md">
          <OpportunityCriteria 
            opportunityId={this.props.opportunity.id}
            userId={this.props.user._id}
            avaliacao={this.state.avaliation.avaliacao}
            dataAvaliationCriteria={this.setEvaliation} />
      </div>)
  }

  allSteps = () => [
    {
      content: (
        <>
          {this.props.evaluations.map((item: any) => {
            return (
              <Field className="mb-4">
                <Radio
                  disabled={item.name === "Desenvolvedor Java"}
                  name="hint-example"
                  value={item.name}
                  checked={this.state.avaliation.avaliacao.name === item.name}
                  onChange={() =>
                    this.setState({
                      avaliation: {
                        ...this.state.avaliation,
                        avaliacao: item,
                      },
                    })
                  }
                >
                  <Label>{t(item.name)}</Label>
                  {
                    item.name === "Desenvolvedor Java" &&
                      <Hint>
                        <Message validation="warning">
                          {t("Avaliação Indisponível")}
                        </Message>
                      </Hint>
                  }
                  {
                    item.name !== "Desenvolvedor Java" &&
                    <Hint>
                      {t("O usuário teve uma avaliação acima da média nessa categoria.")}
                    </Hint>
                  }
                </Radio>
              </Field>
            );
          })}
        </>
      ),
    }
  ].concat(this.addNextSteps());

  addNextSteps = () => {
    if(this.state.avaliation.avaliacao.name === AssessmentType.TechnicalAdvice) {
      return ({
        content: (this.technicalAdvice())
      })
    }

    return {content: (this.nextStep(""))};
  }

  async dispenseCandidate() {
    this.setState({ loading: true });
    try {
      const obj = {
        _usuario: this.props.user._usuario,
        type: "dispensar-candidato",
        activeLanguage: this.props.user._usuario.dadosPessoais 
        && this.props.user._usuario.dadosPessoais.preferredLanguage
          ? this.props.user._usuario.dadosPessoais.preferredLanguage
          : this.state.currentLang(),
        sendDispensedMail: this.state.sendEmail,
        byRh: this.props.user.byRH,
      };
      await OpportunityService.dispenseCandidate(
        this.props.opportunity.id,
        obj
      );
      this.setState(
        {
          loading: false,
          dismissModal: false,
          usuarioDispensado: true,
        },
        () =>
          AlertService.success(
            this.state.sendEmail
              ? t("Email enviado com sucesso")
              : t("Candidato dispensado com sucesso")
          )
      );
    } catch (err: any) {
      this.setState({
        loading: false,
        dismissModal: false,
      });
      AlertService.error(t("Erro"));
      console.log(err.message)
    }
  }

  isEvaluationStar = (evaluation: any[]): boolean => {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado >= 8) {
          result = true;
        }
      });
    }
    return result;
  };

  isEvaluationUpdated(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) > currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationOutOfDate(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) <= currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationNegative(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado === 0) {
          result = true;
        }
      });
    }
    return result;
  }

  getCurrentStep(steps: any[]): any[] {
    steps.sort();
    let listReturn = [];
    for (let i = steps.length - 1; i > -1; i--) {
      if (steps[i].meetsTheRequirements === 0) {
        listReturn.push(steps[i]);
        break;
      }
    }
    return listReturn;
  }

  async handleEmailSend(user: any, opp: any) {
    this.setState({ loading: true });

    const response = await UserService.sendConfirmationEmail(user._usuario._id, opp.id, opp.title);
  
    if (response.status !== 200) {
      this.setState({ loading: false });
      AlertService.warn("Erro no envio de email.");
    }

    this.setState({ loading: false });
    AlertService.success("O pre-cadastro foi realizado com sucesso e o profissional receberá um email de notificação com os próximos passos.");
  }

  selectProfessional() {
    const { user, opportunity } = this.props;
    const data = {
      professionalId: user._usuario._id,
      professionalStatus: !this.state.selectedUser,
    };

    this.setState(
      {
        selectedUser: !this.state.selectedUser,
      },
      async () => {
        await OpportunityService.selectProfessional(opportunity.id, data);
      }
    );
  }

  getOpportunities(processType: string) {
    this.setState({ loading: true, processType }, async () => {
      let opps = (await OpportunityService.getActiveOpportunities()).data;
      this.setState({
        inscribeModal: true,
        opportunities: opps,
        loading: false,
      });
    });
  }

  setFlip() {
    this.setState({ flip: !this.state.flip });
  }

  async becomeCandidateByRh() {
    this.setState({ loading: true }, async () => {
      try {
        let triageRecruitmentOptions;
        if (
          this.props.user.steps &&
          this.props.user.steps.length &&
          this.props.user.capture &&
          this.props.user.source
        ) {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: this.props.user.capture,
            source: this.props.user.source,
          };
        } else {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: "",
            source: "",
          };
        }

        const obj = {
          activeLanguage: this.state.currentLang(),
          _usuario: { _id: this.props.user._usuario._id },
          triageRecruitmentOptions: triageRecruitmentOptions,
          conversionType: this.state.processType,
          byRH: true,
        };
        const resp = await OpportunityService.becomeCandidateByRh(
          this.state.selectedId,
          obj
        );
        this.setState({ inscribeModal: false, loading: false }, () => {
          AlertService.success(t(resp.data.message));
        });
      } catch (error) {
        console.error(error);
        AlertService.error(error);
        this.setState({ loading: false });
      }
    });
  }

  render() {
    let { user, opportunity, level, history } = this.props;
    const professionalElumini = user._usuario?.eluminer?.status;
    if (user) {
      if (!user._usuario) {
        user._usuario = { deleted: true };
      }
      user._usuario.level = user.nivelAderencia;
      user._usuario.byRH = user.byRH;
    } else {
      return <></>;
    }

    // const hasEmailContactPermission = user.notificationsSettings.isActive && user.notificationsSettings.channels[0]?.isActive;

    if (this.state.loading) return <Loading show={true} />;
    return (
      <Col sm={12} md={6} xl={4} key={user.id}>
        <div className={styles["container-flip-card"]}>
          {/* <div className={}> */}
            {this.state.flip ?
              <div className={professionalElumini ? styles["user-card-eluminer-candidate"] : styles["user-card"]}>
                <ProfessionalAvatar
                  user={user._usuario}
                  callback={this.setFlip}
                  oppId={opportunity.id}
                  history={history}
                  level={level}>
                </ProfessionalAvatar>
                {!user._usuario.deleted && (
                  <>
                    <div className="w-100">
                      <Button
                        className={styles["button-gray"]}
                        onClick={() =>
                          window.open(`/professional/${user._usuario._id}`, "_blank")
                        }
                      >
                        {t("Ver currículo")}
                      </Button>
                      <Button
                        onClick={() =>
                          this.props.history.push(
                            `/opportunity/${this.props.opportunity.id}/application/${this.props.user._usuario._id}`
                          )
                        }
                        className={styles["button-orange"]}
                      >
                        {t("Ver candidatura")}
                      </Button>
                      <Button
                        onClick={() => this.setState({ avaliationModal: true })}
                        className={styles["button-gray"]}
                      >
                        {t("Registrar avaliação")}
                      </Button>
                      {user.steps && user.steps.length > 0 && (
                        <Row className="ml-1 mr-1 mt-2 mb-2 u-fs-sm u-semibold">
                          <Col className="p-0">
                            {/* Olhar com a bia */}
                            {this.getCurrentStep(user.steps).length < 1 && (
                              // <span>
                              //   {t("Etapa")}: {t("Inscrito")}
                              // </span>
                              <span>
                                {t("Etapa")}: {t("Triado")}
                              </span>
                            )}
                            {this.getCurrentStep(user.steps).map((step: any, index: number ) => (
                                <React.Fragment key={step.key || index}>
                          
                                {step.key === 1 && (
                                  <span>
                                    {t("Etapa")}: {t("Triado")}
                                  </span>
                                )}
                                {step.key === 2 && (
                                  <span>
                                    {t("Etapa")}: {t("Recrutado")}
                                  </span>
                                )}
                                {step.key === 3 && (
                                  <span>
                                    {t("Etapa")}: {t("Avaliado")}
                                  </span>
                                )}
                                {step.key === 4 && (
                                  <span>
                                    {t("Etapa")}: {t("Apresentado")}
                                  </span>
                                )}
                            </React.Fragment>
                            ))}
                          </Col>
                          <Col className="p-0 text-right">
                          <>
                            {user.status.current === 0 && (
                              <span>{t("Status")}: {t("Registrado")}</span>
                            )}
                            {user.status.current === 1 && (
                              <span>{t("Status")}: {t("Concorrendo")}</span>
                            )}
                            {user.status.current === 2 && (
                              <span>{t("Status")}: {t("Reservado")}</span>
                            )}
                            {user.status.current === 3 && (
                              <span>{t("Status")}: {t("Dispensado")}</span>
                            )}

                            {/* Pegando o último status do histórico */}
                            {user.status.history && user.status.history.length > 0 && (() => {
                              const lastStatus = user.status.history[user.status.history.length - 1]; // Pega o último status
                              if (lastStatus.status === 4 || lastStatus.status === 5) {
                                return (
                                  <>
                                    <span>{t("Status")}: {lastStatus.status === 4 ? t("Recusou") : t("Desistiu")}</span>
                                    <br />
                                    <span>{moment(lastStatus.date).format("DD/MM/YYYY")}</span>
                                    {console.log(lastStatus)}
                                  </>
                                );
                              }
                              return null;
                            })()}

                            {user.status.current === 6 && (
                              <span>{t("Status")}: {t("Selecionado")}</span>
                            )}
                          </>
                        </Col>

                        </Row>
                      )}
                      <Button
                        className={
                          this.state.selectedUser
                            ? styles["button-orange"]
                            : styles["button-white"]
                        }
                        onClick={this.selectProfessional}
                      >
                        {t("Não comunicar encerramento")}
                      </Button>
                      <Button
                        disabled={
                          (user.status && user.status.current === 3) ||
                          this.state.usuarioDispensado
                        }
                        onClick={() => this.setState({ dismissModal: true })}
                        className={styles["button-white"]}
                      >
                        {t("Dispensar Candidato")}
                      </Button>
                      <Button
                        className={styles["button-white"]}
                        onClick={() => this.getOpportunities("inscribe")}
                      >
                        {t("Inscrever em outra oportunidade")}
                      </Button>
                      <Button
                        className={styles["button-white"]}
                        onClick={() => this.getOpportunities("candidate")}
                      >
                        {t("Recrutar p/ outra oportunidade")}
                      </Button>
                      <Button
                        className={styles["button-white"]}
                        onClick={() => this.handleEmailSend(user, opportunity)}
                        disabled={!user._usuario.preRegister}
                      >
                        {t("Reenviar Email Pré-cadastro")}
                      </Button>
                    </div>
                  </>
                )}
                {user._usuario.deleted && (
                  <p
                    className="text-center mt-3 mb-2 u-fs-md u-semibold"
                    style={{ color: "#797979", height: "250px" }}
                  >
                    {t("Cadastro excluído")}
                  </p>
                )}
                {
                  // MODAL DE INSCRIÇÃO
                  this.state.inscribeModal && (
                    <Modal
                      scrollable
                      show={this.state.inscribeModal}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      onHide={() => this.setState({ inscribeModal: false })}
                    >
                      <Modal.Header>
                        <Row>
                          <Col size={12} className="mb-2">
                            {t("Em que oportunidade você quer inscrever @ profissional?")}
                            <button onClick={() => this.setState({ inscribeModal: false })} type="button" className="close" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Col>
                          <Col size={12}>
                            <h4 style={{ color: '#ed961c' }}>{t("Lembre-se de que el@ será notificad@ por e-mail.")}</h4>
                          </Col>
                        </Row>
                      </Modal.Header>
                      <Modal.Body className={styles["style-scroll-bar-candidate"]}>
                        <p className="u-semibold mt-2">{t("Oportunidades")}</p>
                        {this.state.opportunities.map((opp) => (
                          <Button
                            key={opp.id}
                            className={
                              (this.state.selectedId === opp._id
                                ? styles["modal-button-selected"]
                                : styles["modal-button-opp"]) + " mt-2 qualquer"
                            }
                            onClick={() => this.setState({ selectedId: opp._id })}
                          >
                            {opp.titulo}
                            <Tooltip
                              size="small"
                              type="light"
                              content={`${t("Recrutador(a)")}: ${
                                opp.user?.displayName
                              }`}
                              placement="top-end"
                            >
                              <InfoIcon className={styles["info"]} />
                            </Tooltip>
                          </Button>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <FooterItem>
                          <Button
                            disabled={this.state.selectedId === ""}
                            variant="outline-warning"
                            onClick={() => this.becomeCandidateByRh()}
                            style={{ borderRadius: "20px", padding: "3px 25px" }}
                          >
                            {t("Salvar")}
                          </Button>
                        </FooterItem>
                      </Modal.Footer>
                    </Modal>
                  )
                }

                {
                  // MODAL DE REGISTRAR AVALIAÇÃO
                  this.state.avaliationModal && (
                    <Modal
                      show={this.state.avaliationModal}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      onHide={() => this.setState({ avaliationModal: false })}
                    >
                      <Modal.Header className="justify-content-center">
                        <Stepper activeIndex={this.state.currentStep} isHorizontal>
                          <Stepper.Step key="step-1">
                            <Stepper.Label>Escolha uma avaliação</Stepper.Label>
                          </Stepper.Step>
                          <Stepper.Step key="step-2">
                            <Stepper.Label>Escolha a nota</Stepper.Label>
                          </Stepper.Step>
                        </Stepper>
                      </Modal.Header>
                      <Modal.Body>
                        {this.allSteps().map(
                          (step, index) =>
                            index === this.state.currentStep && (
                              <StyledContainer key={index}>
                                {step.content}
                              </StyledContainer>
                            )
                        )}
                      </Modal.Body>
                      <Modal.Footer
                        className={
                          this.state.currentStep !== this.allSteps().length - 1
                            ? ""
                            : "justify-content-between"
                        }
                      >
                        {this.state.currentStep !== 0 && (
                          <FooterItem>
                            <Button
                              onClick={this.onBack}
                              className={styles["modal-button-submit"]}
                              variant="outline-warning"
                              style={{ padding: "3px 30px" }}
                            >
                              {t("Anterior")}
                            </Button>
                          </FooterItem>
                        )}
                        <FooterItem>
                          <Button
                            disabled={
                              Object.keys(this.state.avaliation.avaliacao).length ===
                              0
                            }
                            className={
                              this.state.currentStep !== this.allSteps().length - 1
                                ? styles["modal-button-next"]
                                : styles["modal-button-submit"]
                            }
                            onClick={
                              this.state.currentStep !== this.allSteps().length - 1
                                ? this.onNext
                                : this.submitEvaluation
                            }
                            style={{ padding: "3px 30px" }}
                          >
                            {this.state.currentStep !== this.allSteps().length - 1
                              ? t("Próximo")
                              : t("Finalizar")}
                          </Button>
                        </FooterItem>
                      </Modal.Footer>
                    </Modal>
                  )
                }

                {
                  // MODAL DE ENVIAR E-MAIL DE DISPENSA
                  this.state.dismissModal && (
                    <Modal
                      show={this.state.dismissModal}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      onHide={() => this.setState({ dismissModal: false })}
                    >
                      <Modal.Header>
                        {t("Deseja realmente dispensar este profissional?")}
                      </Modal.Header>
                      <Modal.Body>
                        <Field className="mt-4">
                          <Checkbox
                            checked={this.state.sendEmail}
                            onChange={() =>
                              this.setState({ sendEmail: !this.state.sendEmail })
                            }
                          >
                            <Label>
                              {t("O profissional receberá uma mensagem por e-mail.")}
                            </Label>
                          </Checkbox>
                        </Field>
                      </Modal.Body>
                      <Modal.Footer className="justify-content-between">
                        <FooterItem>
                          <Button
                            onClick={() => this.setState({ dismissModal: false })}
                            variant="secondary"
                            style={{ padding: "3px 30px" }}
                          >
                            {t("Cancelar")}
                          </Button>
                        </FooterItem>
                        <FooterItem>
                          <Button
                            onClick={() => this.dispenseCandidate()}
                            variant="danger"
                            style={{ padding: "3px 30px" }}
                          >
                            {t("Dispensar Candidato")}
                          </Button>
                        </FooterItem>
                      </Modal.Footer>
                    </Modal>
                  )
                }

                {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
                
              </div>
              :
              <div className={styles["back-flip-card"]}>
                <CandidateFlipCard user={user} oppId={this.props.opportunity.id} callback={this.setFlip}/>
              </div>
            }
          {/* </div> */}
        </div>
      </Col>
    );
  }
}

const mapStateToProps = (store: {
  parecerTecnicoReducer: { validateValue: boolean };
}) => ({
  validateValue: store.parecerTecnicoReducer.validateValue,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators(
  {
    getValidateValue,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalCardCandidate);